<template>
  <div
    class="charts-overview-v2 mb-8 d-flex flex-row justify-content-start"
    id="charts_overview_v2"
    v-loading.fullscreen.lock="loading"
  >
    <div class="charts-overview-left-bar me-10">
      <div class="card shadow-card charts-card">
        <div class="card-body">
          <div class="card-head">
            <span class="heading-sideBar"> Charts</span>
            <hr />
          </div>
          <div class="slider-filters">
            <div class="card-sub-headings d-flex align-items-center">
              <div class="el-image icon stats-social-icon pb-1">
                <img
                  class="el-image__inner"
                  src="/media/svg/social-logos/spotify.svg"
                  alt=""
                />
              </div>
              <div class="sub-headings-sidebar m-lg-2">Monthly Listeners</div>
            </div>
            <div class="monthly-listeners-slider">
              <el-slider
                v-model="monthlyListeners"
                :min="monthlyListenersMin"
                :max="monthlyListenersMax"
                @change="valuesChanged"
                range
              />
              <div
                class="
                  pt-2
                  d-flex
                  justify-content-around
                  theme-v2-color
                  align-items-center
                "
              >
                <div class="range-values">
                  <input
                    type="number"
                    class="form-control filter-inputs"
                    v-model="monthlyListenersNum1"
                    :min="monthlyListenersMin"
                    :max="monthlyListenersMax"
                    @change="
                      monthlyListeners = [
                        monthlyListenersNum1,
                        monthlyListenersNum2,
                      ]
                    "
                  />
                </div>
                <div class="fw-boldest ps-4 pe-4">-</div>
                <div class="range-values">
                  <input
                    type="number"
                    class="form-control filter-inputs"
                    v-model="monthlyListenersNum2"
                    :min="monthlyListenersMin"
                    @change="
                      monthlyListeners = [
                        monthlyListenersNum1,
                        monthlyListenersNum2,
                      ]
                    "
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="card-sub-headings d-flex align-items-center">
              <div class="el-image icon stats-social-icon pb-1">
                <img
                  class="el-image__inner"
                  src="/media/svg/social-logos/spotify.svg"
                  alt=""
                />
              </div>
              <div class="sub-headings-sidebar m-lg-2">Followers</div>
            </div>
            <div class="followers-slider">
              <el-slider
                v-model="followers"
                range
                :min="followersMin"
                :max="followersMax"
                @change="valuesChanged"
              />
              <div
                class="
                  pt-2
                  d-flex
                  justify-content-around
                  theme-v2-color
                  align-items-center
                "
              >
                <div class="range-values">
                  <input
                    type="number"
                    class="form-control filter-inputs"
                    :min="followersMin"
                    :max="followersMax"
                    v-model="followersNum1"
                    @change="followers = [followersNum1, followersNum2]"
                  />
                </div>
                <div class="fw-boldest ps-4 pe-4">-</div>
                <div class="range-values">
                  <input
                    type="number"
                    class="form-control filter-inputs"
                    :min="followersMin"
                    :max="followersMax"
                    v-model="followersNum2"
                    @change="followers = [followersNum1, followersNum2]"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="card-sub-headings d-flex align-items-center">
              <div class="el-image icon stats-social-icon pb-1">
                <img
                  class="el-image__inner"
                  src="/media/svg/BR-Blue.svg"
                  alt=""
                />
              </div>
              <div class="sub-headings-sidebar m-lg-2">Fanbase</div>
            </div>
            <div class="fanbase-slider">
              <el-slider
                v-model="fanbase"
                range
                :min="fanbaseMin"
                :max="fanbaseMax"
                @change="valuesChanged"
              />
              <div
                class="
                  pt-2
                  d-flex
                  justify-content-around
                  theme-v2-color
                  align-items-center
                "
              >
                <div class="range-values">
                  <input
                    type="number"
                    class="form-control filter-inputs"
                    :min="fanbaseMin"
                    :max="fanbaseMax"
                    v-model="fanbaseNum1"
                    @change="fanbase = [fanbaseNum1, fanbaseNum2]"
                  />
                </div>
                <div class="fw-boldest ps-4 pe-4">-</div>
                <div class="range-values">
                  <input
                    type="number"
                    class="form-control filter-inputs"
                    :min="fanbaseMin"
                    :max="fanbaseMax"
                    v-model="fanbaseNum2"
                    @change="fanbase = [fanbaseNum1, fanbaseNum2]"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="genres mb-2">
              <div class="sub-headings-sidebar m-lg-2">Genre</div>
              <div class="search-bar">
                <input
                  class="form-control form-control-solid me-3 search-field"
                  placeholder="Search genre"
                  v-model="search"
                />
                <svg
                  class="search-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M23.111 20.058l-4.977-4.977c.965-1.52 1.523-3.322 1.523-5.251 0-5.42-4.409-9.83-9.829-9.83-5.42 0-9.828 4.41-9.828 9.83s4.408 9.83 9.829 9.83c1.834 0 3.552-.505 5.022-1.383l5.021 5.021c2.144 2.141 5.384-1.096 3.239-3.24zm-20.064-10.228c0-3.739 3.043-6.782 6.782-6.782s6.782 3.042 6.782 6.782-3.043 6.782-6.782 6.782-6.782-3.043-6.782-6.782zm2.01-1.764c1.984-4.599 8.664-4.066 9.922.749-2.534-2.974-6.993-3.294-9.922-.749z"
                  />
                </svg>
              </div>
              <div
                v-for="(genre, index) in filteredGenres"
                :key="index"
                class="genre-checkboxes ms-2"
              >
                <el-checkbox
                  v-model="checkedGenre"
                  :label="genre.name"
                  size="large"
                  @change="handleCheckedGenresChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="charts-main-content w-100">
      <div
        class="card shadow-card mb-8"
        v-for="(artist, i) in artistsList"
        :key="i"
      >
        <div class="row m-3">
          <div class="col-4">
            <div class="row">
              <div class="col-1 mt-6">
                <div class="d-flex flex-column align-items-center">
                  <div>
                    <span
                      style="font-size: 18px"
                      class="artist-count theme-v2-color fw-boldest"
                      >{{ i + 1 }}</span
                    >
                  </div>
                  <!--                  <div class="mt-2">-->
                  <!--                    <span class="badge badge-success badge-pill">-->
                  <!--                      <i class="el-icon pe-4">-->
                  <!--                        <svg-->
                  <!--                          viewBox="0 0 1024 1024"-->
                  <!--                          xmlns="http://www.w3.org/2000/svg"-->
                  <!--                        >-->
                  <!--                          <path-->
                  <!--                            d="M572.235 205.282v600.365a30.118 30.118 0 1 1-60.235 0V205.282L292.382 438.633a28.913 28.913 0 0 1-42.646 0 33.43 33.43 0 0 1 0-45.236l271.058-288.045a28.913 28.913 0 0 1 42.647 0L834.5 393.397a33.43 33.43 0 0 1 0 45.176 28.913 28.913 0 0 1-42.647 0l-219.618-233.23z"-->
                  <!--                          ></path>-->
                  <!--                        </svg> </i-->
                  <!--                      >3</span-->
                  <!--                    >-->
                  <!--                  </div>-->
                </div>
              </div>
              <div
                class="
                  col-6
                  artist-image
                  d-flex
                  justify-content-between
                  align-items-center
                "
              >
                <el-image
                  style="
                    width: 180px;
                    height: 180px;
                    min-width: 180px;
                    border-radius: 100%;
                  "
                  :src="
                    artist.imageUrl
                      ? artist.imageUrl
                      : getKeyData(1, artist, 'image_url')
                  "
                  :fit="'fill'"
                />
              </div>
              <div class="col-5 artist-info mt-6">
                <el-tooltip
                  :content="artist.name ? artist.name : ''"
                  placement="bottom"
                  effect="light"
                >
                  <span class="sub-headings-sidebar fw-boldest">
                    {{ artist.name ? artist.name : "" }}
                  </span>
                </el-tooltip>
                <br />
                <div class="d-flex align-items-center mt-5">
                  <div class="el-image icon stats-social-icon pb-1">
                    <img
                      class="el-image__inner"
                      src="/media/svg/BR-Blue.svg"
                      alt="br-blue"
                    />
                  </div>
                  <div class="artist-count fw-boldest theme-v2-color ms-3">
                    1
                  </div>
                </div>
                <div
                  class="d-flex align-items-center mt-1"
                  v-if="getKeyData(2, artist, 'cm_statistics', 'countryRank')"
                >
                  <div class="el-image icon stats-social-icon pb-1">
                    <img
                      class="el-image__inner"
                      :src="
                        require(`country-flag-icons/flags/3x2/${
                          getKeyData(2, artist, 'cm_statistics', 'countryRank')
                            .code2
                        }.svg`)
                      "
                      alt="flag"
                    />
                  </div>
                  <div class="artist-count fw-bolder ms-3">
                    <span>{{
                      getKeyData(2, artist, "cm_statistics", "countryRank")
                        .code2
                    }}</span
                    ><span class="ms-1" style="color: darkgray">{{
                      formatNumberIntoHumanizeForm(
                        getKeyData(2, artist, "cm_statistics", "countryRank")
                          .rank
                      )
                    }}</span>
                  </div>
                </div>
                <div class="mt-8 fw-bolder">
                  <span>{{
                    getKeyData(2, artist, "cm_statistics", "genreRank")
                      ? getKeyData(2, artist, "cm_statistics", "genreRank").name
                      : "N/A"
                  }}</span
                  ><span class="ms-2" style="color: darkgray">{{
                    getKeyData(2, artist, "cm_statistics", "genreRank")
                      ? formatNumberIntoHumanizeForm(
                          getKeyData(2, artist, "cm_statistics", "genreRank")
                            .rank
                        )
                      : "N/A"
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-8">
            <div class="row">
              <div
                class="col-2 mt-6"
                v-for="(platform, index) in getFanMetricStats(
                  getKeyData(1, artist, 'fanMetricStats')
                )"
                :key="index"
                style="border-left: 1px solid #dee4ee"
              >
                <div class="d-flex flex-column">
                  <div class="el-image icon stats-social-icon pb-1">
                    <img
                      class="el-image__inner"
                      :src="'/media/svg/social-logos/' + platform.name + '.svg'"
                      :alt="platform.name"
                    />
                  </div>
                  <div class="fw-bolder initial-font-size mt-2">
                    {{ platform.key1Value ? platform.key1Value : "N/A" }}
                  </div>
                  <div class="fw-bold mt-1" style="color: darkgray">
                    {{ platform.key1Name }}
                  </div>
                  <div class="fw-bolder initial-font-size mt-10">
                    {{ platform.key2Value ? platform.key2Value : "N/A" }}
                  </div>
                  <div class="fw-bold mt-1" style="color: darkgray">
                    {{ platform.key2Name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          card
          shadow-card
          p-3
          align-items-center
          theme-v2-color
          fw-bold
          cursor-pointer
        "
        v-if="showMoreTab"
        @click="showMore"
      >
        + Show More Artists
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, watch } from "vue";
import { getChartmetricGenres } from "@/api/genreResult";
import { getChartsArtistFromMongo } from "@/api/mongoDB";
import { formatNumberIntoHumanizeForm } from "@/utils/staticHelper";

export default {
  name: "ChartsOverviewV2.vue",
  setup() {
    const loading = ref(false);
    const search = ref(null);
    const genres = ref([]);
    const filteredGenres = ref([]);
    const checkedGenre = ref([]);
    const monthlyListeners = ref([0, 5000000]);
    const monthlyListenersMin = ref(0);
    const monthlyListenersMax = ref(10000000);
    const monthlyListenersNum1 = ref(0);
    const monthlyListenersNum2 = ref(5000000);
    const followers = ref([0, 5000000]);
    const followersMin = ref(0);
    const followersMax = ref(10000000);
    const followersNum1 = ref(0);
    const followersNum2 = ref(5000000);
    const fanbase = ref([0, 5000000]);
    const fanbaseMin = ref(0);
    const fanbaseMax = ref(10000000);
    const fanbaseNum1 = ref(0);
    const fanbaseNum2 = ref(5000000);
    const limit = ref(10);
    const artistsList = ref([]);
    const showMoreTab = ref(false);
    const totalArtists = ref(0);
    onMounted(async () => {
      try {
        loading.value = true;
        let { data } = await getChartmetricGenres();
        genres.value = data;
        filteredList();
        if (genres.value.length > 0) {
          checkedGenre.value.push(genres.value[0].name);
          await getArtistByGenre(false);
        }
        data = [];
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    });
    const filteredList = (value) => {
      if (value) {
        filteredGenres.value = genres.value.filter((genre) =>
          genre.name.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        filteredGenres.value = genres.value;
      }
    };
    const getArtistByGenre = async () => {
      try {
        loading.value = true;
        const payload = {
          min_listeners: monthlyListeners.value[0],
          max_listeners: monthlyListeners.value[1],
          min_fanbase: fanbase.value[0],
          max_fanbase: fanbase.value[1],
          min_followers: followers.value[0],
          max_followers: followers.value[1],
          genre: checkedGenre.value,
          limit: limit.value,
        };
        const { data } = await getChartsArtistFromMongo(payload);
        artistsList.value = data;
        if (artistsList.value.length > 0) {
          showMoreTab.value =
            Number.isInteger(artistsList.value.length / 10) &&
            artistsList.value.length > totalArtists.value;
          showMoreTab.value =
            Number.isInteger(artistsList.value.length / 10) &&
            artistsList.value.length >= totalArtists.value;
          totalArtists.value = artistsList.value.length;
        }
      } catch (e) {
        console.log(e);
      } finally {
        loading.value = false;
      }
    };
    const handleCheckedGenresChange = () => {
      if (checkedGenre.value.length > 0) {
        getArtistByGenre();
      }
    };
    const showMore = () => {
      limit.value += 10;
      getArtistByGenre();
    };
    const getKeyData = (position, artist, key, key2) => {
      if (position === 1) {
        if (artist && artist.chartmetricMeta && artist.chartmetricMeta[key]) {
          return artist.chartmetricMeta[key];
        }
      } else if (position === 2) {
        if (
          artist &&
          artist.chartmetricMeta &&
          artist.chartmetricMeta[key][key2]
        ) {
          return artist.chartmetricMeta[key][key2];
        }
      }
      return "";
    };
    const getFanMetricStats = (artist) => {
      const platforms = [
        {
          name: "spotify",
          key1Name: "Followers",
          key2Name: "Listeners",
          key1Value: 0,
          key2Value: 0,
        },
        {
          name: "youtube",
          key1Name: "Subscribers",
          key2Name: "Views",
          key1Value: 0,
          key2Value: 0,
        },
        {
          name: "instagram",
          key1Name: "Followers",
          key2Name: "Likes",
          key1Value: 0,
          key2Value: 0,
        },
        {
          name: "facebook",
          key1Name: "Talks",
          key2Name: "Likes",
          key1Value: 0,
          key2Value: 0,
        },
        {
          name: "tiktok",
          key1Name: "Followers",
          key2Name: "Likes",
          key1Value: 0,
          key2Value: 0,
        },
        {
          name: "melon",
          key1Name: "Fans",
          key2Name: "Likes",
          key1Value: 0,
          key2Value: 0,
        },
      ];
      if (artist) {
        for (const platform of platforms) {
          if (platform.name === "spotify") {
            platform.key1Value = formatNumberIntoHumanizeForm(
              artist.spotify_followers
            );
            platform.key2Value = formatNumberIntoHumanizeForm(
              artist.spotify_listeners
            );
          } else if (platform.name === "youtube") {
            platform.key1Value = formatNumberIntoHumanizeForm(
              artist.youtube_channel_subscribers
            );
            platform.key2Value = formatNumberIntoHumanizeForm(
              artist.youtube_channel_views
            );
          } else if (platform.name === "instagram") {
            platform.key1Value = formatNumberIntoHumanizeForm(
              artist.instagram_followers
            );
            platform.key2Value = "N/A";
          } else if (platform.name === "facebook") {
            platform.key1Value = formatNumberIntoHumanizeForm(
              artist.facebook_talks
            );
            platform.key2Value = formatNumberIntoHumanizeForm(
              artist.facebook_likes
            );
          } else if (platform.name === "tiktok") {
            platform.key1Value = formatNumberIntoHumanizeForm(
              artist.tiktok_followers
            );
            platform.key2Value = formatNumberIntoHumanizeForm(
              artist.tiktok_likes
            );
          } else if (platform.name === "melon") {
            platform.key1Value = formatNumberIntoHumanizeForm(
              artist.melon_fans
            );
            platform.key2Value = "N/A";
          }
        }
        return platforms;
      }
      return platforms;
    };
    const valuesChanged = () => {
      monthlyListenersNum1.value = monthlyListeners.value[0];
      monthlyListenersNum2.value = monthlyListeners.value[1];
      followersNum1.value = followers.value[0];
      followersNum2.value = followers.value[1];
      fanbaseNum1.value = fanbase.value[0];
      fanbaseNum2.value = fanbase.value[1];
      getArtistByGenre();
    };
    watch(
      () => search.value,
      (value) => {
        filteredList(value);
      }
    );
    return {
      loading,
      search,
      filteredGenres,
      monthlyListeners,
      monthlyListenersMin,
      monthlyListenersMax,
      monthlyListenersNum1,
      monthlyListenersNum2,
      followers,
      followersMin,
      followersMax,
      followersNum1,
      followersNum2,
      fanbase,
      fanbaseMin,
      fanbaseMax,
      fanbaseNum1,
      fanbaseNum2,
      checkedGenre,
      limit,
      artistsList,
      showMoreTab,
      handleCheckedGenresChange,
      valuesChanged,
      showMore,
      getKeyData,
      getFanMetricStats,
      formatNumberIntoHumanizeForm,
    };
  },
};
</script>
<style scoped lang="scss">
.charts-overview-left-bar {
  .charts-card {
    width: 300px;

    .card-body {
      padding: 1rem !important;

      .slider-filters {
        .card-sub-headings {
          font-size: 14px;
          color: #33647f !important;
        }

        .el-slider {
          padding: 0 6px;
        }

        .genres {
          input::placeholder {
            color: #33647f !important;
            font-size: 12px;
            font-weight: 400;
          }

          .search-bar {
            position: relative;
            width: -webkit-fill-available;
            padding-bottom: 1rem !important;
          }

          .search-field {
            padding: 0.75rem 1.5rem !important;
            line-height: 0 !important;
          }

          .search-icon {
            position: absolute;
            right: 4%;
            font-size: 24px;
            bottom: 42%;
            fill: #33647f;
          }

          .genre-checkboxes {
            .el-checkbox {
              color: black;
            }
          }
        }
      }
    }
  }
}

.stats-social-icon {
  width: 25px;
  height: 25px;
}

.artist-image {
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
}

.initial-font-size {
  font-size: initial;
}

@media screen and (max-width: 1250px) {
  .charts-overview-left-bar .charts-card {
    width: 250px;
  }
}

.filter-inputs {
  border: none;
  background-color: aliceblue;
  padding: 4px 16px !important;
  text-align: center;
  font-weight: 400;
  color: #33647f !important;
}

.shadow-card:hover {
  cursor: pointer;
  box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5) !important;
  transition: all 0.3s ease !important;
}
</style>
